@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@font-face{
    font-family: porkys;
    src: url('../fonts/porkys/PORKYS_.TTF');
}

:root{
    --basic-blue: #E9FAFF;
    --light-blue: #C4F2FF;
    --dark-blue: #54D9FF;
    --basic-red: #FBC8CA;
    --light-red: #F484B7;
    --dark-red: #ED3237;
    --basic-grey: #F3F3F3;
}

body{
    background-image: url("../img/memberauth/signupbg.png");
    background-size: cover;
}

.bodyWrapper{
    background-color: white;
    margin-top:5%;
    padding: 20px 10px 20px 10px;
}

.bodyLogo{
    max-width: 80px;
}

.buyPlanButton{
    padding: 5px 15px 5px 15px;
    border:none;
    border-radius: 25px;
    color: white;
    background-color: var(--dark-red);
}

.planTabs,.profile-body-area {
    background: var(--light-blue);
}

.planCard{
    border: none;
    background: var(--basic-blue);
}

.planCard h1,h2,h3{
    font-weight:600;
    font-family: Roboto;
}

.planCard .planPrice{
    font-weight:600;
    font-family: Roboto;
}

