@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@font-face{
    font-family: porkys;
    src: url('../fonts/porkys/PORKYS_.TTF');
}

:root{
    --basic-blue: #E9FAFF;
    --light-blue: #C4F2FF;
    --dark-blue: #54D9FF;
    --basic-red: #FBC8CA;
    --light-red: #F484B7;
    --dark-red: #ED3237;
    --basic-grey: #F3F3F3;
}

body{
    background-image: url("../img/memberauth/signupbg.png");
    background-size: cover;
}

.bodyWrapper{
    background-color: white;
    margin-top:5%;
    padding: 20px;
    /* width: 100%; */
}

@media only screen and (min-width: 600px) {
  .bodyWrapper{
      padding: 20px 50px 20px 50px;
      width: 80%;
      margin-left:10%;
  }
}

.bodyLogo{
    max-width: 150px;
    text-align: left;
}

.signupSideImage{
    max-width: 100%;
}

.signupHeroHeader{
    font-family: porkys;
    color: var(--dark-blue);
}

.signupHeroSubHeader{
    font-family: Roboto;
    font-size: 20px;
}



.indomitableForm textarea {
  outline: none;
  border: none;
}

.indomitableForm textarea:focus, input:focus {
  /* border-color: transparent !important; */
}

.indomitableForm input::-webkit-input-placeholder { color: #666666;}
.indomitableForm input:-moz-placeholder { color: #666666;}
.indomitableForm input::-moz-placeholder { color: #666666;}
.indomitableForm input:-ms-input-placeholder { color: #666666;}

.indomitableForm textarea::-webkit-input-placeholder { color: #666666;}
.indomitableForm textarea:-moz-placeholder { color: #666666;}
.indomitableForm textarea::-moz-placeholder { color: #666666;}
.indomitableForm textarea:-ms-input-placeholder { color: #666666;}

.indomitableForm .wrap-input100 {
  width: 100%;
  position: relative;
  margin-top: 25px;
  background-color: #fff;
  /* border: 1px solid #e6e6e6; */
}

.indomitableForm .wrap-input100.rs1 {
  border-top: none;
}

.indomitableForm .input100 {
  display: block;
  width: 100%;
  font-family: Roboto;
  /* font-size: 15px; */
  color: #666666;
  line-height: 1.2;
  border-radius: 25px;
  border:none;
}


/*---------------------------------------------*/
.indomitableForm input,select.input100 {
  height: 50px;
  padding: 0 25px 0 25px;
  background-color: var(--basic-grey);
}

.indomitableForm .control-label {
  display: block;
  position: absolute;
  opacity: 0;
  bottom: 1.9rem;
  color: #5d5d5d;
  transition: 0.2s ease-in-out transform;
  font-size: 12px;
}
.indomitableForm .input100:placeholder-shown + .control-label {
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-in-out;
}
.indomitableForm .input100:not(:placeholder-shown) + .control-label,
.input100:focus:not(:placeholder-shown) + .control-label {
  visibility: visible;
  margin-left: 15px;
  z-index: 1;
  opacity: 1;
  transform: translateY(-10px);
  transition: 0.2s ease-in-out transform;
}

.indomitableForm select.input100 + .control-label{
    visibility: visible;
    margin-left: 15px;
    z-index: 1;
    opacity: 1;
    transform: translateY(-10px);
    transition: 0.2s ease-in-out transform;
}

.indomitableForm button{
    display: block;
    background-color: var(--dark-red);
    border: none;
    border-radius: 25px;
    padding: 10px;
    color: white;
    font-family: Roboto;
}

.indomitableForm .alternativeActionText{
    font-family: Roboto;
    /* font-size: 13px; */
}

.indomitableForm .alternativeActionText a{
    color: var(--dark-red);
}

